import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import "./index.css"
import Image from "../components/image"
import SEO from "../components/seo"

const BlogLink = styled(Link)`
  text-decoration: none;
`

const BlogTitle = styled.h3`
  margin-bottom: 20px;
  color: blue;
`

export default ({ data }) => (
  <Layout>
    <SEO title="Tesla2024" keywords={[`tesla`, `investment`, `josh`, `niederer`, `experiment`, `2024`]} />
    <div className="wallpaper">
      <div className="hero-text">
        <h1>Tesla2024</h1>
        <h3>An Experiment in Investing</h3>
      </div>
    </div>
    <p>Welcome to Tesla2024. Tesla2024 is an experiment in investing. The experiment has started, but the site work has only just begun. Updates are to come.</p>
    <p>This site is under construction.</p>
    <h2>Posts</h2>
    {
      data.allMarkdownRemark.edges.map(({node}) => (
        <div className="px-6 py-4 rounded overflow-hidden shadow-sm" key={node.id}>
          <Link className="font-bold text-xl mb-2 text-blue-500 hover:underline" to={node.fields.slug}>
            <BlogTitle>{node.frontmatter.title }</BlogTitle>
          </Link>
          <p>Posted on: {node.frontmatter.date }</p>
          <p className="text-gray-700 text-base">{node.excerpt}</p>
        </div>
      ))
    }
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`